.Hero {
    margin: 40px 0 50px 0;
    max-height: 40vh;
    display: flex;
    justify-content: center;
}

.Hero-img {
    max-width: 40%;
    max-height: 35vh;
    object-fit: contain;
}

.Hero-chat {
    max-width: 40%;
}

.Hero-slogan, .Hero-shoutout {
    text-align: left;
    background-color: white;
    padding: 4px;
    max-width: 150px;
    border: 3px solid;
    border-radius: 17px 17px 17px 1px;
}

.Hero-shoutout {
    animation: 0.75s ease-in 0s 1 fadeInShoutout;
}

.Hero-slogan {
    animation: 1.5s ease-in 0s 1 fadeInShoutout;
}

@keyframes fadeInShoutout {
  0% {
    transform: scale(0%);
    opacity: 0;
  }
  100% {
    transform: scale(100%);
    opacity: 1;
  }
}