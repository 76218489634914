.Banner {
    margin: 40px 0 100px 0;
    max-height: 60vh;
    display: block;
    align-content: center;
    flex-direction: row;
}

.Banner-chat {
    margin: auto;
    max-width: 40%;
}

.Banner-slogan, .Banner-shoutout, .Banner-guidance {
    text-align: left;
    background-color: white;
    padding: 4px;
    max-width: 150px;
    border: 3px solid;
    border-radius: 17px 17px 17px 1px;
}

.Banner-slogan {
    margin-top: 20px;
    margin-left: auto;
    margin-bottom: 20px;
    border-radius: 17px 17px 1px 17px;
}

.Banner-shoutout {
    margin-top: 20px;
    margin-left: 50px;
    margin-bottom: 20px;
    border-radius: 17px 17px 1px 17px;
}
