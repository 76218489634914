.Footer {
    background-color: rgba(10,47,77,0.7);
    background: linear-gradient(0deg, rgba(10,47,77,1) 0%, rgba(10,47,77,0) 100%);
    color: white;
    padding: 40px 0 50px 0;
    max-height: 60vh;
    display: block;
    align-content: center;
    flex-direction: row;
}

.Footer a {
    color: grey;
}