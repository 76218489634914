.link {
    text-decoration: none;
}

.App-header {
    background-color: rgba(10,47,77,0.7);
    background: linear-gradient(180deg, rgba(10,47,77,1) 0%, rgba(10,47,77,0) 100%);
    padding: 40px 40px 40px 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    align-content: center;
}

.Header-left {
    object-position: 0px 6px;
}

.App-logo {
    padding-bottom: 0px;
    max-height: 60px;
}

.App-icon {
    max-height: 60px;
    scale: 170%;
    object-position: 5px 6px;
}

.Platforms {
    color: rgba(256,256,256,0.7);
    margin-bottom: 0px;
}