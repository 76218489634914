.row-wrapper {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
}

.row-wrapper:nth-child(6) {
    border-top: 0;
}

.row {
    display: flex;
    flex-direction: row;
    max-width: 100vw;
    padding: 20px;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-snap-type: x proximity;
    /* Hide scrollbar for row */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.row::-webkit-scrollbar {
  display: none;
}

.row a {
    text-decoration: none;
}

.row-header {
    padding-left: 20px;
    text-align: left;
    color: white;
}

.row-subtitle {
    padding-left: 20px;
    text-align: left;
    color: white;
    font-weight: 200;
}

