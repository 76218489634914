.game-card {
    color: white;
    padding: 20px;
    scroll-snap-align: center;
    max-width: 310px;
}

.game-card img {
    background-color: black;
    width: 300px;
    height: 150px;
    object-fit: cover;
}

.game-card img:hover, .game-card img:focus {
    transform: scale(1.3);
}

.game-card > a {
    color: white;
}

.game-card img { transition: all .2s ease-in-out; }
