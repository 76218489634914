.Faq {
    color: white;
    margin: 0 auto;
    text-align: center;
    max-width: 40%
}

.FaqList {
    display: flex;
    flex-direction: column;
    align-content: center;
}

.Faq h2 {
}

.Question {
    font-weight: 700
}

.QuestionPair {
    list-style-position: inside;
}
